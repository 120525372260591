p {
    margin: 0;
}

.panel-container {
    min-height: calc(100vh - 123px);
    overflow-x: hidden;
    transition: all 250ms ease-out;
}

.panel-container .panel {
    padding: 15px 15px 0;
}

.react-tel-input {
    margin-top: 16px;
}

.react-tel-input .flag-dropdown {
    bottom: 5px;
}

.MuiInput-underline:before {
    border-bottom: 1px solid #d2d2d2 !important;
}

.MuiInput-underline:after {
    border-color: #5c8bcd !important;
}

.MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.5) !important;
}

.MuiAppBar-colorPrimary {
   /* background: linear-gradient(160deg, #cbe100 0%, #5c8bcd 50%);*/
}

.data-grid.info .data-grid__head {
    background-color: #5c8bcd;
    color: #f5f5f5;
}

.data-grid.info .MuiButton-textPrimary {
    color: #5c8bcd;
}

.data-grid.info .MuiDataGrid-root {
    border: 1px solid #5c8bcd;
}

.data-grid.info .MuiBadge-colorPrimary {
    background-color: #5c8bcd;
}

.data-grid.primary .data-grid__head {
    color: #9da927;
}

.data-grid.primary .MuiButton-textPrimary {
    color: #9da927;
}

.data-grid.primary .MuiDataGrid-root {
    border: 1px solid #9da927;
}

.data-grid.primary .MuiBadge-colorPrimary {
    background-color: #9da927;
}

.data-grid.rose .data-grid__head {
    color: #343434;
}

.data-grid.rose .MuiButton-textPrimary {
    color: #343434;
}

.data-grid.rose .MuiDataGrid-root {
    border: 1px solid #343434;
}

.data-grid.rose .MuiBadge-colorPrimary {
    background-color: #343434;
}

.data-grid.no-border .MuiDataGrid-root {
    border: 0px !important;
}

.data-grid .data-grid__head {
    background-color: rgba(0, 0, 0, 0.15);
    color: #444;
}

.data-grid .MuiButton-textPrimary {
    color: #444;
}

.data-grid .MuiDataGrid-root {
    border: 1px solid #666;
}

.data-grid .MuiBadge-colorPrimary {
    background-color: #5c8bcd;
}

.data-grid.no-border .MuiDataGrid-root {
    border: 0px !important;
}

.country-list {
    text-align: left !important;
    z-index: 5 !important;
}

.MuiSelect-select {
    text-align: left;
}

.user-profile-card {
    /* height: 485px; (height 13)*/
    height: 520px; /* (height 14) */
    width: 100%;
    flex: 1 1 auto;
}
.user-profile-card .react-json-view {
    overflow: auto;
    height: 440px;
    width: 100%;
    flex: 1 1 auto;
}

.card-list-label {
    margin-top: 7px;
}

.tooltip-info-icon {
    font-size: 20px;
    display: inline-block;
    border: 1px solid #888;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 3px;
    line-height: 17px;
    color: #888;
    cursor: pointer;
}

.info-label {
    background-color: #ccc;
    display: inline-block;
    margin: 0 10px;
    padding: 0 15px;
    color: #fff;
    border-radius: 10px;
    font-weight: 400;
    text-transform: initial;
}

.info-label.warning {
    background-color: #fb8c00;
    color: #f5f5f5;
}

.info-label.danger {
    background-color: #e53935;
    color: #f5f5f5;
}

.info-label.success {
    background-color: #43a047;
    color: #f5f5f5;
}

/* text colors */
.text-success {
    color: #43a047;
}

.text-warning {
    color: #fb8c00;
}

.text-danger {
    color: #e53935;
}
.text-bold {
    font-weight: bold;
}

@media (max-width: 959px) {
    .panel-container {
        margin-left: 0px;
    }
}

/* helpers */
.column {
    display: flex;
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}
.justify-start {
    justify-content: flex-start;
}
.justify-end {
    justify-content: flex-end;
}
.justify-between {
    justify-content: space-between;
}
.justify-around {
    justify-content: space-around;
}
.justify-evenly {
    justify-content: space-evenly;
}
.align-center {
    align-items: center;
}
.align-start {
    align-items: flex-start;
}
.align-end {
    align-items: flex-end;
}
.align-self-center {
    align-items: center;
}
.align-self-start {
    align-items: flex-start;
}
.align-self-end {
    align-items: flex-end;
}
.gap-5 {
    gap: 5px;
}
.gap-10 {
    gap: 10px;
}
.gap-20 {
    gap: 20px;
}
.cursor-pointer {
    cursor: "pointer";
}
.round {
    border-radius: 50%;
}
/* overrides */
.ct-label {
    font-size: 15px;
    opacity: 1;
    font-weight: bold;
}

.blue-pie {
    stroke: rgb(74, 114, 170);
    fill: rgb(92, 139, 205);
    opacity: 0.8;
}
.yellow-pie {
    stroke: rgb(177, 196, 1);
    fill: rgb(203, 225, 0);
    opacity: 0.8;
}

/* position styles */
.absolute {
    position: absolute;
}
.top-left {
    top: 0px;
    left: 0px;
}

.top-right {
    top: 0px;
    right: 0px;
}
.bottom-left {
    bottom: 0px;
    left: 0px;
}
.bottom-right {
    bottom: 0px;
    right: 0px;
}
